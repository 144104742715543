@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

:root {
  --primary-color: #FBB5AD;
  --secondary-color: #FFE1E1;
  --terciary-color: #FF8F8F;
  --four-color: #FFB6B6;
  --primary-font: 'Montserrat', sans-serif;
  --secondary-font: 'Nunito Sans', sans-serif;
}

body {
  margin: 0;
  background-color: #ffffff;
}


/*  HOME  */

.home svg path {
  color: #e98383;
}
.whatsapp {
  position: fixed;
  bottom: 20px; /* Ajusta la distancia desde la parte inferior según tu preferencia */
  right: 20px; /* Ajusta la distancia desde la derecha según tu preferencia */
  z-index: 999; /* Asegura que esté por encima de otros elementos */
  padding: 15px; /* Ajusta el tamaño del botón según tu preferencia */
  border-radius: 50%; /* Hace que el botón sea circular */
  cursor: pointer;
}
path:hover {
  fill: #000000;
}
/*  NAVBAR  */

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  font-family: var(--primary-font);
  padding: 20px;
  position: relative;
}
hr {
  width: 90%;
}

.mobile-menu {
  display: none;
}

  .menu ul img {
    padding: .5rem;
  }
  ul { 
    margin: 2px auto 0; 
    padding: 0; 
    list-style: none; 
    display: table;
    width: 600px;
    text-align: center;
    display: flex;
    align-items: center;
  }
  li { 
    display: table-cell; 
    position: relative; 
    padding: 15px 0;
  }
  ul li > a {
    color: #000000;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.15em;
    
    display: inline-block;
    padding: 15px 20px;
    position: relative;
  }
  ul li a:after {    
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: var(--primary-color);
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  a:hover:after { 
    width: 100%; 
    left: 0; 
  }

/*  HEADER  */

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

}
.textCabecera {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header h1 {
  color: black;
  font-family: var(--primary-font);
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 60px;
  font-weight: 500;
  line-height: 1.18333em;
}
.header p {
  font-size: 1.2rem;
  font-family: var(--primary-font);
  width: 70%;
  text-align: center;
  padding: .5rem;
}
.header .btn {
  padding: 1rem;
  font-family: var(--primary-font);
  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: #FF8F8F;
  border: none;
  transition: 1s;
}
.btn:hover {
  background-color: #000000;
  transition: 1s;
}
.btn a {
  text-decoration: none;
  color: #FAF7F4;
}

.home-hero-underline {
  position: relative; /* Necesario para posicionar correctamente el tachado */
  color: black; /* Color del texto */
}

.home-hero-underline::before {
  content: ''; /* Contenido del pseudo-elemento, en este caso, la línea */
  position: absolute;
  width: 100%; /* Asegura que la línea cubra todo el ancho del texto */
  height: 40%; /* Ajusta la altura de la línea según tus preferencias */
  bottom: 0; /* Coloca la línea detrás del texto */
  left: 0;
  background-color: pink; /* Color del tachado */
  z-index: -1; /* Coloca el tachado detrás del texto */
}

.header div img {
  width: 700px;
}

/*  SERVICIOS  */

.serviceGlobal {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: url("../src/assets/back02.png") center;
}
.servicios {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px; /* Espacio entre los elementos internos */
  padding: 2rem;
}
.graph1,
.graph2,
.graph3 {
  text-align: center;
}
.titleServicios {
  font-family: var(--primary-font);
  padding: 5rem;
  text-align: center;
  font-size: 3rem;
}
.servicios h3 {
  font-family: var(--primary-font);
}
.servicios p {
  font-family: var(--secondary-font);
  text-align: center;
}
.servicios img {
  transition: transform 0.3s ease;
}
.servicios img:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease;
}

/*  NOSOTROS  */
.nosotrosGlobal {
  background: url("../src/assets/back03.png");
}
.nosotros {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3rem;
}
.textNosotros {
  font-family: var(--primary-font);
  font-size: 1.5rem;
  word-spacing: 10px;
}
.titleNosotros {
  font-family: var(--primary-font);
  padding: 1rem;
  text-align: center;
  font-size: 3rem;
}

/*  CONTACTO  */

@import url('https://fonts.googleapis.com/css?family=Lato:400,700');

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

::-webkit-input-placeholder {
  color: #56585b;
}

::-moz-placeholder {
  color: #56585b;
}

:-moz-placeholder {
  color: #56585b;
}

.contact {
  font-family: 'Lato', sans-serif;
  margin: 0;
  background: url('../src/assets/deco.jpg') center fixed;
  background-position: center;
  color: #0a0a0b;
  overflow: hidden;
}

h1 {
  font-family: var(--primary-font);
  text-align: center;
  font-size: 3rem;
  color: #ffffff;
}

.cover  {
  height: 100vh;
  width: 100%;
  background: --webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,0.05)), to(rgba(0,0,0,0)));
  background: -webkit-linear-gradient(top, rgba(0,0,0,0.05) 0%, rgba(0,0,0,0) 100%);
  background: linear-gradient(to bottom, rgba(0,0,0,0.05) 0%, rgba(0,0,0,0) 100%);
  padding: 20px 50px;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}
.cover p {
  color: #ffffff;
  font-size: 1.2rem;
}
.enviar {
  display: flex;
  align-items: center;
}
.enviar input[type="submit"] {
  background: var(--primary-color);
  width: 14%;
  color: #000000;
  padding: 0 30px;
  cursor: pointer;
  transition: all 1s;
  border-radius: 1.5rem;
  line-height: 50px;
  font-size: 1rem;
}

.enviar input[type="submit"]:hover {
  background: #000000;
  color: #ffffff;
  transition: 1s;
}

.flex-form {
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 1rem;
}

.flex-form > * {
  padding: 0 0 0 10px;
  line-height: 50px;
  font-size: 1rem;
  border-radius: 1.5rem;
}


@media all and (max-width:800px) {
  .flex-form {
    width: 100%;
  }

  input[type="search"] {
    flex-basis: 100%;
  }

  .flex-form > * {
    font-size: 0.9rem;
  }

  h1 {
    font-size: 2rem;
  }

  .cover {
    padding: 20px;
  }
}

@media all and (max-width:360px) {
 
  .flex-form {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }

  input[type="search"] {
    flex-basis: 0;
  }

  label {
    display: none;
  }
}


/*  INSTAGRAM  */
.container-feed {
  padding: 10rem;
  background: url("../src/assets/background.png") center;
}
.titulosIG {
  display: flex;
  flex-direction: column;
}
.container-feed h2 {
  font-family: var(--primary-font);
  font-size: 3rem;
  text-align: center;
  padding: 3rem;
}
.container-feed p {
  text-align: center;
  font-size: 1.2rem;
  font-family: var(--secondary-font);
}
.container-feed p a {
  text-decoration: none;
  color: rgb(0, 0, 0);
}

.instagram-feed {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    padding: 3rem;
    grid-gap: 1px; 
}
.instagram-feed img {
  width: 90%;
  height: 90%;
  object-fit: cover;
  cursor: pointer;
  transition: transform 0.3s ease;
}
.instagram-feed img:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease;
}

.instagram-feed img:nth-child(1) {
  grid-column-start: span 1;
  grid-row-start: span 1;
}

.instagram-feed a:nth-child(2) {
  grid-column-start: span 2;
  grid-row-start: span 2;
}



/*  FOOTER  */

.footer {
  background-color: #FAF7F4;
  color: rgb(0, 0, 0);
  height: 200px;
  padding: 3rem;
  text-align: center;
  font-family: var(--primary-font);
  font-size: .6rem;
  margin-top: 3rem;
}
footer a {
  text-decoration: none;
  color: rgba(234, 0, 255, 0.637)
}
.infoContact {
  display: flex;
  justify-content:space-around;
  padding: 3rem;
}
.infoContact span {
  color: #000000;
}
.infoContact a {
  text-decoration: none;
  color: rgba(234, 0, 255, 0.637)
}
.anchor {
  color: rgba(234, 0, 255, 0.637) !important
}
.textFooter {
  text-align: justify;
  font-size: .8rem;
  color: rgb(0, 0, 0);
  font-family: var(--primary-font);
}
.icons:hover {
  filter: invert(50%);
}

  /*  ENVIADO  */

.enviado {
  margin: 0;
  background-image: url("../src/assets/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: 90vh;
  width: 100%;
  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.titleEnviado {
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  color: rgb(0, 0, 0);
  padding: 1rem;
  margin-bottom: -9rem;
  font-size: 2.2rem;
  margin-left: 2rem;
  text-align: center;
  height: 50vh;
}

.btnEnviado {
  color: rgb(255, 255, 255);;
  background-color: var(--primary-color);
  padding: .5rem;
  border-radius: .5rem;
  border: none;
  font-weight: bold;
  font-family: var(--primary-font);
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: 0 5px 20px pink;
  transition: box-shadow .3s ease-in-out;
}
.btnEnviado:hover {
  background-color: var(--primary-color);
  color: rgb(255, 255, 255);
  box-shadow: none;
  transition: box-shadow .3s ease-in-out;
}
.contenedorBtn {
  display: flex;
  justify-content: center;
  padding: 1rem;
}
@media (max-width: 699px) {
  .titleEnviado {
    font-size: 1.5rem;
  }  
}
  .mensajeExito {
    color: var(--primary-color);
  }



  /*  ----------RESPONSIVE----------  */

  

  /*  RESOLUCIÓN 320px  */

  /*  HOME 320px */

  @media (max-width: 320px) {
    .whatsapp {
      bottom: 60px !important; /* Ajusta la distancia desde la parte inferior según tu preferencia */      
    }
  }

  /*  NAVBAR  */
  
  @media (max-width: 320px) {
    .menu ul {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      background-color: #ffffff;
      padding: 20px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      z-index: 1;
    }
  
    .menu li {
      margin-bottom: 10px;
      display: flex;
    }
  
    .mobile-menu {
      display: block;
      cursor: pointer;
    }
  
    .hamburger {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 18px;
    }
  
    .bar {
      width: 25px;
      height: 3px;
      background-color: #000;
    }
  
    .menu.active ul {
      display: block;
    }
  }
  /*  HEADER 320px */

  @media (max-width:320px) {
    .header {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      align-content: center;
    }
    
    .header h1 {
      color: black;
      font-family: var(--primary-font);
      margin-top: 0;
      margin-bottom: 16px;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.18333em;
      text-align: center;
    }
    .header p {
      font-size: 11px;
      font-family: var(--primary-font);
      text-align: center;
      padding: 1rem;
    }
    .containerBtn {
      text-align: center;
      padding: 1rem;
    }
    .header .btn {
      font-family: var(--primary-font);
      cursor: pointer;
      color: rgb(0, 0, 0);
      background-color: #FF8F8F;
      border: none;
      transition: 1s;
      text-align: center;
    }
    .btn:hover {
      background-color: #000000;
      transition: 1s;
    }
    .btn a {
      text-decoration: none;
      color: #FAF7F4;
    }
    
    .home-hero-underline {
      position: relative; /* Necesario para posicionar correctamente el tachado */
      color: black; /* Color del texto */
    }
    
    .home-hero-underline::before {
      content: ''; /* Contenido del pseudo-elemento, en este caso, la línea */
      position: absolute;
      width: 100%; /* Asegura que la línea cubra todo el ancho del texto */
      height: 40%; /* Ajusta la altura de la línea según tus preferencias */
      bottom: 0; /* Coloca la línea detrás del texto */
      left: 0;
      background-color: pink; /* Color del tachado */
      z-index: -1; /* Coloca el tachado detrás del texto */
    }
    
    .header div img {
      width: 350px;
    }
  }

  /*  SERVICIOS 320px*/

  
  @media (max-width: 320px) {

    .servicios {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px; /* Espacio entre los elementos internos */
      padding: 2rem;
    }
    .titleServicios {
      font-family: var(--primary-font);
      padding: 5rem;
      text-align: center;
      font-size: 2.6rem;
    }
  }

  /*  NOSOTROS 320px  */

  @media (max-width:320px) {
    .nosotrosGlobal {
      background: url("../src/assets/back03.png");
    }
    .nosotros {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 3rem;
    }
    .alexandra {
      width: 300px !important;
    }
    .textNosotros {
      font-family: var(--primary-font);
      font-size: 1rem;
    }
  }

  /*  CONTACTO 320px */

  @media (max-width: 320px) {
  .cover h1 {
    text-align: start;
  }
  .cover p {
    display: none;
  }
  .flex-form input {
    width: 82%;
  }
  .flex-form textarea {
    width: 82%;
  }
  }

  /*  INSTAGRAM 320px  */

  @media (max-width:320px) {

    .container-feed {
      padding: 1rem;
    }
    .container-feed h2 {
      font-family: var(--primary-font);
      font-size: 2.5rem;
      padding: 1rem;
    }
    .titulosIG {
      display: flex;
      flex-direction: column;
    }
    .container-feed p {
      text-align: center;
      font-size: 1.2rem;
      font-family: var(--secondary-font);
    }
    .instagram-feed {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      padding: 1rem;
      grid-gap: 1px; 
  }
  .instagram-feed img {
    width: 90%;
    height: 90%;
    object-fit: cover;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  .instagram-feed img:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
  }
  .instagram-feed img:nth-child(1) {
    grid-column-start: span 1;
    grid-row-start: span 1;
  }
  .instagram-feed a:nth-child(2) {
    grid-column-start: span 1;
    grid-row-start: span 2;
  }
  }

  /*  FOOTER 320px */

  @media (max-width: 320px) {
    .footer {
      height: 250px;
    }
    .textFooter {
      text-align: start;
    }
    footer a {
      text-decoration: none;
      color: rgba(234, 0, 255, 0.637)
    }
    .infoContact {
      display: flex;
      justify-content:center;
      align-items: center;
      padding: 2rem;
    }
    .infoContact span {
      color: #000000;
    }
    .infoContact a {
      text-decoration: none;
      color: rgba(234, 0, 255, 0.637)
    }
    .anchor {
      color: rgba(234, 0, 255, 0.637) !important
    }
  }





  /*  RESOLUCIÓN 375px  */


  /*  NAVBAR 375px */

  @media (max-width: 375px) {
    .menu ul {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      background-color: #fff;
      padding: 20px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);      
      z-index: 1;
    }
  
    .menu li {
      margin-bottom: 10px;
      display: flex;
    }
  
    .mobile-menu {
      display: block;
      cursor: pointer;
    }
  
    .hamburger {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 18px;
    }
  
    .bar {
      width: 25px;
      height: 3px;
      background-color: #000;
    }
  
    .menu.active ul {
      display: block;
    }
  }
   /*  HEADER 375px */

   @media (max-width:375px) {
    .header {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      align-content: center;
    }
    
    .header h1 {
      color: black;
      font-family: var(--primary-font);
      margin-top: 0;
      margin-bottom: 16px;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.18333em;
      text-align: center;
    }
    .header p {
      font-size: 11px;
      font-family: var(--primary-font);
      text-align: center;
      padding: 1rem;
    }
    .containerBtn {
      text-align: center;
      padding: 1rem;
    }
    .header .btn {
      font-family: var(--primary-font);
      cursor: pointer;
      color: rgb(0, 0, 0);
      background-color: #FF8F8F;
      border: none;
      transition: 1s;
      text-align: center;
    }
    .btn:hover {
      background-color: #000000;
      transition: 1s;
    }
    .btn a {
      text-decoration: none;
      color: #FAF7F4;
    }
    
    .home-hero-underline {
      position: relative; /* Necesario para posicionar correctamente el tachado */
      color: black; /* Color del texto */
    }
    
    .home-hero-underline::before {
      content: ''; /* Contenido del pseudo-elemento, en este caso, la línea */
      position: absolute;
      width: 100%; /* Asegura que la línea cubra todo el ancho del texto */
      height: 40%; /* Ajusta la altura de la línea según tus preferencias */
      bottom: 0; /* Coloca la línea detrás del texto */
      left: 0;
      background-color: pink; /* Color del tachado */
      z-index: -1; /* Coloca el tachado detrás del texto */
    }
    
    .header div img {
      width: 350px;
    }
  }
  /*  SERVICIOS 375px*/

  
  @media (max-width: 375px) {

    .servicios {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px; /* Espacio entre los elementos internos */
      padding: 2rem;
    }
    .titleServicios {
      font-family: var(--primary-font);
      padding: 5rem;
      text-align: center;
      font-size: 2.6rem;
    }
  }
   /*  NOSOTROS 375px  */

   @media (max-width:375px) {
    .nosotrosGlobal {
      background: url("../src/assets/back03.png");
    }
    .home-hero-underline {
      position: relative !important; /* Necesario para posicionar correctamente el tachado */
      color: black !important; /* Color del texto */
    }
    
    .home-hero-underline::before {
      content: '' !important; /* Contenido del pseudo-elemento, en este caso, la línea */
      position: absolute !important;
      width: 100% !important; /* Asegura que la línea cubra todo el ancho del texto */
      height: 40% !important; /* Ajusta la altura de la línea según tus preferencias */
      bottom: 0 !important; /* Coloca la línea detrás del texto */
      left: 0 !important;
      background-color: pink !important; /* Color del tachado */
      z-index: -1 !important; /* Coloca el tachado detrás del texto */
    }
    .nosotros {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 3rem;
    }
    .alexandra {
      width: 300px !important;
    }
    .textNosotros {
      font-family: var(--primary-font);
      font-size: 1rem;
    }
  }
   /*  CONTACTO 375px */

   @media (max-width: 375px) {
    .cover h1 {
      text-align: start;
    }
    .cover p {
      display: none;
    }
    .flex-form input {
      width: 82%;
    }
    .flex-form textarea {
      width: 82%;
    }
    }
     /*  INSTAGRAM 375px  */

  @media (max-width:375px) {

    .container-feed {
      padding: 1rem;
    }
    .container-feed h2 {
      font-family: var(--primary-font);
      font-size: 2.5rem;
      padding: 1rem;
    }
    .titulosIG {
      display: flex;
      flex-direction: column;
    }
    .container-feed p {
      text-align: center;
      font-size: 1.2rem;
      font-family: var(--secondary-font);
    }
    .instagram-feed {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      padding: 1rem;
      grid-gap: 1px; 
  }
  .instagram-feed img {
    width: 90%;
    height: 90%;
    object-fit: cover;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  .instagram-feed img:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
  }
  .instagram-feed img:nth-child(1) {
    grid-column-start: span 1;
    grid-row-start: span 1;
  }
  .instagram-feed a:nth-child(2) {
    grid-column-start: span 1;
    grid-row-start: span 2;
  }
  }
  /*  FOOTER 375px */

  @media (max-width: 375px) {
    .footer {
      height: 250px;
    }
    .textFooter {
      text-align: start;
    }
    footer a {
      text-decoration: none;
      color: rgba(234, 0, 255, 0.637)
    }
    .infoContact {
      display: flex;
      justify-content:center;
      align-items: center;
      padding: 2rem;
    }
    .infoContact span {
      color: #000000;
    }
    .infoContact a {
      text-decoration: none;
      color: rgba(234, 0, 255, 0.637)
    }
    .anchor {
      color: rgba(234, 0, 255, 0.637) !important
    }
  }

  /*  RESOLUCIÓN 425px  */

  /*  NAVBAR 425px */

  @media (max-width: 425px) {
    .menu ul {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      background-color: #fff;
      padding: 20px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);      
      z-index: 1;
    }
  
    .menu li {
      margin-bottom: 10px;
      display: flex;
    }
  
    .mobile-menu {
      display: block;
      cursor: pointer;
    }
  
    .hamburger {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 18px;
    }
  
    .bar {
      width: 25px;
      height: 3px;
      background-color: #000;
    }
  
    .menu.active ul {
      display: block;
    }
  }
  /*  HEADER 425px */

  @media (max-width:425px) {
    .header {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      align-content: center;
    }
    
    .header h1 {
      color: black;
      font-family: var(--primary-font);
      margin-top: 0;
      margin-bottom: 16px;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.18333em;
      text-align: center;
    }
    .header p {
      font-size: 11px;
      font-family: var(--primary-font);
      text-align: center;
      padding: 1rem;
    }
    .containerBtn {
      text-align: center;
      padding: 1rem;
    }
    .header .btn {
      font-family: var(--primary-font);
      cursor: pointer;
      color: rgb(0, 0, 0);
      background-color: #FF8F8F;
      border: none;
      transition: 1s;
      text-align: center;
    }
    .btn:hover {
      background-color: #000000;
      transition: 1s;
    }
    .btn a {
      text-decoration: none;
      color: #FAF7F4;
    }
    
    .home-hero-underline {
      position: relative; /* Necesario para posicionar correctamente el tachado */
      color: black; /* Color del texto */
    }
    
    .home-hero-underline::before {
      content: ''; /* Contenido del pseudo-elemento, en este caso, la línea */
      position: absolute;
      width: 100%; /* Asegura que la línea cubra todo el ancho del texto */
      height: 40%; /* Ajusta la altura de la línea según tus preferencias */
      bottom: 0; /* Coloca la línea detrás del texto */
      left: 0;
      background-color: pink; /* Color del tachado */
      z-index: -1; /* Coloca el tachado detrás del texto */
    }
    
    .header div img {
      width: 350px;
    }
  }
  /*  SERVICIOS 425px*/

  
  @media (max-width: 425px) {

    .servicios {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px; /* Espacio entre los elementos internos */
      padding: 2rem;
    }
    .titleServicios {
      font-family: var(--primary-font);
      padding: 5rem;
      text-align: center;
      font-size: 2.1rem;
    }
  }
   /*  NOSOTROS 425px  */

   @media (max-width:425px) {
    .nosotrosGlobal {
      background: url("../src/assets/back03.png");
    }
    .home-hero-underline {
      position: relative !important; /* Necesario para posicionar correctamente el tachado */
      color: black !important; /* Color del texto */
    }
    
    .home-hero-underline::before {
      content: '' !important; /* Contenido del pseudo-elemento, en este caso, la línea */
      position: absolute !important;
      width: 100% !important; /* Asegura que la línea cubra todo el ancho del texto */
      height: 40% !important; /* Ajusta la altura de la línea según tus preferencias */
      bottom: 0 !important; /* Coloca la línea detrás del texto */
      left: 0 !important;
      background-color: pink !important; /* Color del tachado */
      z-index: -1 !important; /* Coloca el tachado detrás del texto */
    }
    .nosotros {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 3rem;
    }
    .alexandra {
      width: 300px !important;
    }
    .textNosotros {
      font-family: var(--primary-font);
      font-size: 1rem;
    }
  }
    /*  CONTACTO 425px */

    @media (max-width: 425px) {
      .cover h1 {
        text-align: start;
        width: 90%;
      }
      .cover p {
        display: none;
      }
      .flex-form input {
        width: 82%;
      }
      .flex-form textarea {
        width: 82%;
      }
      }
       /*  INSTAGRAM 425px  */
  
    @media (max-width:425px) {
  
      .container-feed {
        padding: 1rem;
      }
      .container-feed h2 {
        font-family: var(--primary-font);
        font-size: 2.5rem;
        padding: 1rem;
      }
      .titulosIG {
        display: flex;
        flex-direction: column;
      }
      .container-feed p {
        text-align: center;
        font-size: 1.2rem;
        font-family: var(--secondary-font);
      }
      .instagram-feed {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 1rem;
        grid-gap: 1px; 
    }
    .instagram-feed img {
      width: 90%;
      height: 90%;
      object-fit: cover;
      cursor: pointer;
      transition: transform 0.3s ease;
    }
    .instagram-feed img:hover {
      transform: scale(1.1);
      transition: transform 0.3s ease;
    }
    .instagram-feed img:nth-child(1) {
      grid-column-start: span 1;
      grid-row-start: span 1;
    }
    .instagram-feed a:nth-child(2) {
      grid-column-start: span 1;
      grid-row-start: span 2;
    }
    }
    /*  FOOTER 425px */
  
    @media (max-width: 425px) {
      .footer {
        height: 250px;
      }
      .textFooter {
        text-align: start;
      }
      footer a {
        text-decoration: none;
        color: rgba(234, 0, 255, 0.637)
      }
      .infoContact {
        display: flex;
        justify-content:center;
        align-items: center;
        padding: 2rem;
      }
      .infoContact span {
        color: #000000;
      }
      .infoContact a {
        text-decoration: none;
        color: rgba(234, 0, 255, 0.637)
      }
      .anchor {
        color: rgba(234, 0, 255, 0.637) !important
      }
    }


    /*  RESOLUCIÓN 768px  */

  /*  NAVBAR 768px */

  @media (max-width: 768px) {
    .menu ul {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      background-color: #fff;
      padding: 20px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);      
      z-index: 1;
    }
  
    .menu li {
      margin-bottom: 10px;
      display: flex;
    }
  
    .mobile-menu {
      display: block;
      cursor: pointer;
    }
  
    .hamburger {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 18px;
    }
  
    .bar {
      width: 25px;
      height: 3px;
      background-color: #000;
    }
  
    .menu.active ul {
      display: block;
    }
  }
  /*  HEADER 768px */

  @media (max-width:768px) {
    .header {
      display: flex;
      align-items: center;
      justify-items: center;
      padding: 1rem;
    }
    
    .header h1 {
      color: black;
      font-family: var(--primary-font);
      margin-top: 0;
      margin-bottom: 16px;
      font-size: 28px;
      font-weight: 500;
      line-height: 1.18333em;
      text-align: center;
    }
    .header p {
      font-size: 11px;
      font-family: var(--primary-font);
      text-align: center;
      padding: 1rem;
    }
    .containerBtn {
      text-align: center;
      padding: 1rem;
    }
    .header .btn {
      font-family: var(--primary-font);
      cursor: pointer;
      color: rgb(0, 0, 0);
      background-color: #FF8F8F;
      border: none;
      transition: 1s;
      text-align: center;
    }
    .btn:hover {
      background-color: #000000;
      transition: 1s;
    }
    .btn a {
      text-decoration: none;
      color: #FAF7F4;
    }
    
    .home-hero-underline {
      position: relative; /* Necesario para posicionar correctamente el tachado */
      color: black; /* Color del texto */
    }
    
    .home-hero-underline::before {
      content: ''; /* Contenido del pseudo-elemento, en este caso, la línea */
      position: absolute;
      width: 100%; /* Asegura que la línea cubra todo el ancho del texto */
      height: 40%; /* Ajusta la altura de la línea según tus preferencias */
      bottom: 0; /* Coloca la línea detrás del texto */
      left: 0;
      background-color: pink; /* Color del tachado */
      z-index: -1; /* Coloca el tachado detrás del texto */
    }
    
    .header div img {
      width: 350px;
    }
  }
  /*  SERVICIOS 768px*/

  
  @media (max-width: 768px) {

    .titleServicios {
      font-family: var(--primary-font);
      padding: 5rem;
      text-align: center;
      font-size: 2.1rem;
    }
  }
   /*  NOSOTROS 768px  */

   @media (max-width:768px) {
    .nosotrosGlobal {
      background: url("../src/assets/back03.png");
    }
    .home-hero-underline {
      position: relative !important; /* Necesario para posicionar correctamente el tachado */
      color: black !important; /* Color del texto */
    }
    
    .home-hero-underline::before {
      content: '' !important; /* Contenido del pseudo-elemento, en este caso, la línea */
      position: absolute !important;
      width: 100% !important; /* Asegura que la línea cubra todo el ancho del texto */
      height: 40% !important; /* Ajusta la altura de la línea según tus preferencias */
      bottom: 0 !important; /* Coloca la línea detrás del texto */
      left: 0 !important;
      background-color: pink !important; /* Color del tachado */
      z-index: -1 !important; /* Coloca el tachado detrás del texto */
    }
    .nosotros {
      display: flex;
      align-items: center;
      padding: 3rem;
    }
    .alexandra {
      width: 300px !important;
    }
    .textNosotros {
      font-family: var(--primary-font);
      font-size: 1rem;
    }
  }

/*  CONTACTO 768px */

    @media (max-width: 768px) {
      .cover h1 {
        text-align: start;
        width: 90%;
      }
      .cover p {
        display: none;
      }
      .flex-form input {
        width: 82%;
      }
      .flex-form textarea {
        width: 82%;
      }
      }

/*  INSTAGRAM 768px  */
  
    @media (max-width:768px) {
  
      .container-feed {
        padding: 1rem;
      }
      .container-feed h2 {
        font-family: var(--primary-font);
        font-size: 2.5rem;
        padding: 1rem;
      }
      .titulosIG {
        display: flex;
        flex-direction: column;
      }
      .container-feed p {
        text-align: center;
        font-size: 1.2rem;
        font-family: var(--secondary-font);
      }
      .instagram-feed {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 1rem;
        grid-gap: 1px; 
    }
    .instagram-feed img {
      width: 90%;
      height: 90%;
      object-fit: cover;
      cursor: pointer;
      transition: transform 0.3s ease;
    }
    .instagram-feed img:hover {
      transform: scale(1.1);
      transition: transform 0.3s ease;
    }
    .instagram-feed img:nth-child(1) {
      grid-column-start: span 1;
      grid-row-start: span 1;
    }
    .instagram-feed a:nth-child(2) {
      grid-column-start: span 1;
      grid-row-start: span 2;
    }
    }
    /*  FOOTER 768px */
  
    @media (max-width: 768px) {
      .footer {
        height: 250px;
      }
      .textFooter {
        text-align: start;
      }
      footer a {
        text-decoration: none;
        color: rgba(234, 0, 255, 0.637)
      }
      .infoContact {
        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items: self-start;
        padding: 1rem;
      }
      .infoContact span {
        color: #000000;
      }
      .infoContact a {
        text-decoration: none;
        color: rgba(234, 0, 255, 0.637)
      }
      .anchor {
        color: rgba(234, 0, 255, 0.637) !important
      }
    }

  /*  1024px  */

  /*  HEADER 1024px */

  @media (max-width:1024px) {
    .header {
      display: flex;
      align-items: center;
      justify-items: center;
      padding: 1rem;
    }
    
    .header h1 {
      color: black;
      font-family: var(--primary-font);
      margin-top: 0;
      margin-bottom: 16px;
      font-size: 40px;
      font-weight: 500;
      line-height: 1.18333em;
      text-align: center;
    }
    .header p {
      font-size: 13px;
      font-family: var(--primary-font);
      text-align: center;
      padding: 1rem;
    }
    .containerBtn {
      text-align: center;
      padding: 1rem;
    }
    .header .btn {
      font-family: var(--primary-font);
      cursor: pointer;
      color: rgb(0, 0, 0);
      background-color: #FF8F8F;
      border: none;
      transition: 1s;
      text-align: center;
    }
    .btn:hover {
      background-color: #000000;
      transition: 1s;
    }
    .btn a {
      text-decoration: none;
      color: #FAF7F4;
    }
    
    .home-hero-underline {
      position: relative; /* Necesario para posicionar correctamente el tachado */
      color: black; /* Color del texto */
    }
    
    .home-hero-underline::before {
      content: ''; /* Contenido del pseudo-elemento, en este caso, la línea */
      position: absolute;
      width: 100%; /* Asegura que la línea cubra todo el ancho del texto */
      height: 40%; /* Ajusta la altura de la línea según tus preferencias */
      bottom: 0; /* Coloca la línea detrás del texto */
      left: 0;
      background-color: pink; /* Color del tachado */
      z-index: -1; /* Coloca el tachado detrás del texto */
    }
    
    .header div img {
      width: 450px;
    }
  }
     /*  NOSOTROS 1024px  */

     @media (max-width:1024px) {
      .nosotrosGlobal {
        background: url("../src/assets/back03.png");
      }
      .home-hero-underline {
        position: relative !important; /* Necesario para posicionar correctamente el tachado */
        color: black !important; /* Color del texto */
      }
      
      .home-hero-underline::before {
        content: '' !important; /* Contenido del pseudo-elemento, en este caso, la línea */
        position: absolute !important;
        width: 100% !important; /* Asegura que la línea cubra todo el ancho del texto */
        height: 40% !important; /* Ajusta la altura de la línea según tus preferencias */
        bottom: 0 !important; /* Coloca la línea detrás del texto */
        left: 0 !important;
        background-color: pink !important; /* Color del tachado */
        z-index: -1 !important; /* Coloca el tachado detrás del texto */
      }
      .nosotros {
        display: flex;
        align-items: center;
        padding: 3rem;
      }
      .alexandra {
        width: 300px !important;
      }
      .textNosotros {
        font-family: var(--primary-font);
        font-size: 1rem;
      }
    }
    /*  INSTAGRAM 1024px  */
  
    @media (max-width:1024px) {
  
      .container-feed {
        padding: 1rem;
      }
      .container-feed h2 {
        font-family: var(--primary-font);
        font-size: 2.5rem;
        padding: 1rem;
      }
      .titulosIG {
        display: flex;
        flex-direction: column;
      }
      .container-feed p {
        text-align: center;
        font-size: 1.2rem;
        font-family: var(--secondary-font);
      }
      .instagram-feed {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        padding: 1rem;
        grid-gap: 1px; 
    }
    .instagram-feed img {
      width: 90%;
      height: 90%;
      object-fit: cover;
      cursor: pointer;
      transition: transform 0.3s ease;
    }
    .instagram-feed img:hover {
      transform: scale(1.1);
      transition: transform 0.3s ease;
    }
    .instagram-feed img:nth-child(1) {
      grid-column-start: span 1;
      grid-row-start: span 1;
    }
    .instagram-feed a:nth-child(2) {
      grid-column-start: span 1;
      grid-row-start: span 2;
    }
    }